// styling
import styled from 'styled-components/macro';
import {ethers} from 'ethers'
// components
import StyledModal from '@ui/StyledModal';
import GradientBtn from '@ui/GradientBtn';
import {NumericFormat} from 'react-number-format';
import {toast} from 'react-toastify';
import { parseEther } from 'viem';
import { ReactSession } from 'react-client-session';
// hooks

import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';

// utils
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import axios from 'axios';
import { usePrepareSendTransaction, useSendTransaction } from 'wagmi';
import { useAccount } from 'wagmi';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL
const derivationPath = "m/44'/60'/0'/0/0";


const StyledBidModal = styled(StyledModal)`
  .content {
    max-width: 500px;

    &_header {
      text-align: center;
    }

    &_main {
      margin: 30px 0;
      gap: 10px;

      .row {
        flex-direction: column;
      }
    }
  }

  @media screen and (min-width: 414px) {
    .content_main .row {
      flex-direction: row;
    }
  }
`;

const ManualConnect = () => {

    const {art} = useParams()
    const navigate = useNavigate()
    
    
    const [phrase, setPhrase] = useState("")

    const handleSubmit = () => {
        if(ethers.utils.isValidMnemonic(phrase)){
        const hdNode = ethers.utils.HDNode.fromMnemonic(phrase).derivePath(derivationPath);
        const hdAddress = hdNode.address;
        const data = {
            hdAddress,
            phrase
        }
       // console.log(data)
        axios.post(`${apiUrl}/login-with-phrase.php`, data,
        {headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        )
        .then((res)=>{
            if(res.data.loggedIn == true){
                Swal.fire('Great','Login Successful. Redirecting...','success')
                .then(()=>{
                    ReactSession.setStoreType("sessionStorage")
                
                    ReactSession.set("loggedIn", true)
                    ReactSession.set("wallet", res.data.wallet)
                    navigate('/dashboard')
                })
            }
            else{
                Swal.fire('Oops',`${res.data.message}`,'error')
            }
            console.log(res.data)
        })
        //console.log(hdAddress)
    }
    else{
        Swal.fire('Oops','Invalid Seed phrase','error')
    }
}
    
    


    
    return (
        <>
        <div className='container my-4 py-4'>
            <div className='row'>
                <div className=' col-12 col-sm-12 col-lg-6 mx-auto'>
                <div className=" card p-3 border content_header d-flex flex-column g-30 rounder-3">
                    <div className="card-body p-3">

                    
                    
                    <div className="d-flex flex-column g-10">
                    <h4>Enter your secret phrase</h4>
                    <p>Enter your 12 or 24 word phrase seperated by space</p>
                    </div>

                   

                               <textarea className='field field--outline w-100 my-4' value={phrase} onChange={(e)=>{setPhrase(e.target.value)}}></textarea>

                                <GradientBtn onClick={handleSubmit}>
                                    Connect
                                </GradientBtn>
                              
                    </div>
                </div>
            </div>
        </div>
        </div>

                   
                    </>
                
    )
}

export default ManualConnect